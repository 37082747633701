import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppState } from './state/app/app.state';
import { StateClear } from 'ngxs-reset-plugin';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public isLoggedIn$ = this.store.select(AppState.isLoggedIn);
  public environmentName = environment.name;
  public isProduction = environment.production;

  constructor(
    private store: Store,
    private router: Router,
  ) {
  }

  public logout() {
    this.store.dispatch(new StateClear());
    this.router.navigateByUrl('/').then();
  }
}
