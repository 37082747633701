import { LoggerModel, LoggerType } from './logger.model';

export class LoggerStoreService {
  static ERRORS: LoggerModel[] = [];
  static LOGS: LoggerModel[] = [];

  /**
   * Add a new LOG or ERROR to the store.
   *
   * @param className
   * @param loggerType
   * @param args
   * * @constructor
   */
  static ADD(className: any, loggerType: LoggerType, ...args: any[]): void {
    const loggerModel: LoggerModel = {
      className: className,
      datetime: new Date(),
      stack: args,
    };
    if (args && args.length > 1) {
      loggerModel.text = args[0];
    }
    switch (loggerType) {
      case LoggerType.LOG:
        if (!LoggerStoreService.LOGS) {
          LoggerStoreService.LOGS = [];
        }
        if (LoggerStoreService.LOGS.length >= 100) {
          LoggerStoreService.LOGS.pop();
        }
        LoggerStoreService.LOGS.push(loggerModel);
        break;
      case LoggerType.ERROR:
        if (!LoggerStoreService.ERRORS) {
          LoggerStoreService.ERRORS = [];
        }
        if (LoggerStoreService.ERRORS.length >= 100) {
          LoggerStoreService.ERRORS.pop();
        }
        LoggerStoreService.ERRORS.push(loggerModel);
        break;
    }
  }
}
