import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  MypbAdminPagesService,
  MypbDomain,
  MypbPage,
  MypbProfile,
  MypbTemplate,
} from '../../../_generated/mypagebuilder-rest-api';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import * as slugify from 'slugify';

@Component({
  selector: 'app-pages-details-page',
  templateUrl: './pages-details-page.component.html',
  styleUrls: ['./pages-details-page.component.scss'],
})
export class PagesDetailsPageComponent {
  @Output() onSuccess: EventEmitter<void> = new EventEmitter<void>();
  public formGroup: FormGroup;
  public page: MypbPage | undefined;

  constructor(
    private readonly store: Store,
    private readonly mypbAdminPagesService: MypbAdminPagesService,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.formGroup = new FormGroup({
      id: new FormControl('', []),
      createdAt: new FormControl('', []),
      modifiedAt: new FormControl('', []),
      name: new FormControl('', [Validators.required]),
      profileId: new FormControl('', [Validators.required]),
      domainId: new FormControl('', [Validators.required]),
      templateId: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams['profileId']) {
      this.formGroup.patchValue({
        profileId: this.activatedRoute.snapshot.queryParams['profileId'],
      });
    }
  }

  ngAfterViewInit() {
    if (this.activatedRoute.snapshot.params['id']) {
      this.mypbAdminPagesService
        .pagesAdminControllerFindOne({
          id: this.activatedRoute.snapshot.params['id'],
        })
        .subscribe(value => {
          this.page = value;
          const { profile, domain, template, ...entity } = value;
          this.formGroup.setValue(entity);
        });
    }
  }

  /**
   * Creating/updating entry when clicking the save button.
   */
  public save() {
    if (this.activatedRoute.snapshot.params['id']) {
      this.update().subscribe({
        next: this.next,
        error: this.error,
      });
    } else {
      this.create().subscribe({
        next: this.next,
        error: this.error,
      });
    }
  }

  public setProfile(entity: MypbProfile) {
    if (entity.id) {
      this.formGroup.patchValue({
        profileId: entity.id,
      });
      if (!entity.pages || !entity.pages.length) {
        this.formGroup.patchValue({
          name: slugify.default(`${entity.firstname} ${entity.lastname}`, {
            lower: true,
            locale: 'de',
            replacement: '.',
          }),
        });
      }
    }
  }

  public setDomain(entity: MypbDomain) {
    if (entity.id) {
      this.formGroup.patchValue({
        domainId: entity.id,
      });
    }
  }

  public setTemplate(entity: MypbTemplate) {
    if (entity.id) {
      this.formGroup.patchValue({
        templateId: entity.id,
      });
    }
  }

  /**
   * Just the API call to update an existing entry.
   *
   * @private
   */
  private update() {
    return this.mypbAdminPagesService.pagesAdminControllerUpdate({
      id: this.formGroup.value.id as string,
      body: this.formGroup.value,
    });
  }

  /**
   * Just the API call to create a new entry.
   *
   * @private
   */
  private create() {
    return this.mypbAdminPagesService.pagesAdminControllerCreate({
      body: this.formGroup.value,
    });
  }

  /**
   * Fired after successfully saving the entry (applies to both created or updated).
   *
   * @param response
   */
  private next = (response: MypbPage) => {
    swal.fire('Gespeichert!', '', 'success');
    this.onSuccess.emit();
  };

  /**
   * Fired after error during saving the entry (applies to both created or updated).
   */
  private error = () => {
    swal.fire('Oops', 'Beim speichern ist ein Fehler aufgetreten.', 'error');
  };
}
