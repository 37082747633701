import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  MypbAdminPagesService,
  MypbAdminProfilesService,
  MypbPage,
  MypbProfile,
} from '../../_generated/mypagebuilder-rest-api';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';

@Component({
  selector: 'app-profiles-page',
  templateUrl: './profiles-page.component.html',
  styleUrls: ['./profiles-page.component.scss'],
})
export class ProfilesPageComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort | undefined;
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  public displayedColumns: string[] = ['id', 'firstname', 'lastname', 'contentTitle', 'contentLeadtext', 'pages', 'contentImageId', 'contentVideoId', 'wpUserId', 'createdAt', 'modifiedAt', 'actions'];
  public dataSource = new MatTableDataSource<MypbProfile>();

  constructor(
    private readonly store: Store,
    private readonly mypbAdminProfilesService: MypbAdminProfilesService,
    private readonly mypbAdminPagesService: MypbAdminPagesService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.initializeData();
  }

  ngOnDestroy(): void {
  }

  public initializeData() {
    this.mypbAdminProfilesService.profilesAdminControllerFindAll()
      .subscribe(result => {
        this.dataSource = new MatTableDataSource(result);
        if (this.sort) {
          this.dataSource.sort = this.sort;
        }
        if (this.paginator) {
          this.dataSource.paginator = this.paginator;
        }
      });
  }

  openDetails(row: any) {
    this.router.navigate([row.id], { relativeTo: this.activatedRoute }).then();
  }

  delete(row: any) {
    this.mypbAdminProfilesService.profilesAdminControllerDelete({
      id: row.id,
    }).subscribe({
      next: () => {
        this.initializeData();
      },
    });
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
    } else {
    }
  }

  deletePage(page: MypbPage) {
    if (page.id) {
      this.mypbAdminPagesService.pagesAdminControllerDelete({
        id: page.id,
      }).subscribe({
        next: () => {
          swal.fire('Gelöscht!', `Die Seite "${page.name}" wurde gelöscht.`, 'success');
          this.initializeData();
        },
        error: () => {
          swal.fire('Oops!', `Seite konnte nicht gelöscht werden.`, 'error');
        },
      });
    }
  }
}
