/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MypbBlock } from '../models/mypb-block';
import { MypbBlockBlog } from '../models/mypb-block-blog';
import { MypbBlockBlogEntry } from '../models/mypb-block-blog-entry';
import { MypbBlockContact } from '../models/mypb-block-contact';
import { MypbBlockDocuments } from '../models/mypb-block-documents';
import { MypbBlockGallery } from '../models/mypb-block-gallery';
import { MypbBlockText } from '../models/mypb-block-text';
import { MypbBlockType } from '../models/mypb-block-type';
import { MypbUpdateBlockBlogDto } from '../models/mypb-update-block-blog-dto';
import { MypbUpdateBlockBlogEntryDto } from '../models/mypb-update-block-blog-entry-dto';
import { MypbUpdateBlockContactDto } from '../models/mypb-update-block-contact-dto';
import { MypbUpdateBlockDocumentsDto } from '../models/mypb-update-block-documents-dto';
import { MypbUpdateBlockDto } from '../models/mypb-update-block-dto';
import { MypbUpdateBlockGalleryDto } from '../models/mypb-update-block-gallery-dto';
import { MypbUpdateBlockTextDto } from '../models/mypb-update-block-text-dto';

@Injectable({
  providedIn: 'root',
})
export class MypbAdminBlocksService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation blocksAdminControllerFindAllBlockTypes
   */
  static readonly BlocksAdminControllerFindAllBlockTypesPath = '/v1/admin/blocks/blockTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blocksAdminControllerFindAllBlockTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  blocksAdminControllerFindAllBlockTypes$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<MypbBlockType>>> {

    const rb = new RequestBuilder(this.rootUrl, MypbAdminBlocksService.BlocksAdminControllerFindAllBlockTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MypbBlockType>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `blocksAdminControllerFindAllBlockTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  blocksAdminControllerFindAllBlockTypes(params?: {
    context?: HttpContext
  }
): Observable<Array<MypbBlockType>> {

    return this.blocksAdminControllerFindAllBlockTypes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MypbBlockType>>) => r.body as Array<MypbBlockType>)
    );
  }

  /**
   * Path part for operation blocksAdminControllerCreate
   */
  static readonly BlocksAdminControllerCreatePath = '/v1/admin/blocks/{technicalName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blocksAdminControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blocksAdminControllerCreate$Response(params: {
    technicalName: string;
    context?: HttpContext
    body: (MypbUpdateBlockDto | MypbUpdateBlockTextDto | MypbUpdateBlockBlogDto | MypbUpdateBlockDocumentsDto | MypbUpdateBlockContactDto | MypbUpdateBlockBlogEntryDto | MypbUpdateBlockGalleryDto)
  }
): Observable<StrictHttpResponse<(MypbBlock | MypbBlockText | MypbBlockBlog | MypbBlockDocuments | MypbBlockContact | MypbBlockBlogEntry | MypbBlockGallery)>> {

    const rb = new RequestBuilder(this.rootUrl, MypbAdminBlocksService.BlocksAdminControllerCreatePath, 'post');
    if (params) {
      rb.path('technicalName', params.technicalName, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<(MypbBlock | MypbBlockText | MypbBlockBlog | MypbBlockDocuments | MypbBlockContact | MypbBlockBlogEntry | MypbBlockGallery)>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `blocksAdminControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blocksAdminControllerCreate(params: {
    technicalName: string;
    context?: HttpContext
    body: (MypbUpdateBlockDto | MypbUpdateBlockTextDto | MypbUpdateBlockBlogDto | MypbUpdateBlockDocumentsDto | MypbUpdateBlockContactDto | MypbUpdateBlockBlogEntryDto | MypbUpdateBlockGalleryDto)
  }
): Observable<(MypbBlock | MypbBlockText | MypbBlockBlog | MypbBlockDocuments | MypbBlockContact | MypbBlockBlogEntry | MypbBlockGallery)> {

    return this.blocksAdminControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<(MypbBlock | MypbBlockText | MypbBlockBlog | MypbBlockDocuments | MypbBlockContact | MypbBlockBlogEntry | MypbBlockGallery)>) => r.body as (MypbBlock | MypbBlockText | MypbBlockBlog | MypbBlockDocuments | MypbBlockContact | MypbBlockBlogEntry | MypbBlockGallery))
    );
  }

  /**
   * Path part for operation blocksAdminControllerRemove
   */
  static readonly BlocksAdminControllerRemovePath = '/v1/admin/blocks/{technicalName}/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blocksAdminControllerRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  blocksAdminControllerRemove$Response(params: {
    technicalName: string;
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MypbAdminBlocksService.BlocksAdminControllerRemovePath, 'delete');
    if (params) {
      rb.path('technicalName', params.technicalName, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `blocksAdminControllerRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  blocksAdminControllerRemove(params: {
    technicalName: string;
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.blocksAdminControllerRemove$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation blocksAdminControllerUpdate
   */
  static readonly BlocksAdminControllerUpdatePath = '/v1/admin/blocks/{technicalName}/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blocksAdminControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blocksAdminControllerUpdate$Response(params: {
    technicalName: string;
    id: string;
    context?: HttpContext
    body: (MypbUpdateBlockDto | MypbUpdateBlockTextDto | MypbUpdateBlockBlogDto | MypbUpdateBlockDocumentsDto | MypbUpdateBlockContactDto | MypbUpdateBlockBlogEntryDto | MypbUpdateBlockGalleryDto)
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MypbAdminBlocksService.BlocksAdminControllerUpdatePath, 'patch');
    if (params) {
      rb.path('technicalName', params.technicalName, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `blocksAdminControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  blocksAdminControllerUpdate(params: {
    technicalName: string;
    id: string;
    context?: HttpContext
    body: (MypbUpdateBlockDto | MypbUpdateBlockTextDto | MypbUpdateBlockBlogDto | MypbUpdateBlockDocumentsDto | MypbUpdateBlockContactDto | MypbUpdateBlockBlogEntryDto | MypbUpdateBlockGalleryDto)
  }
): Observable<void> {

    return this.blocksAdminControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
