<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>myPageBuilder Admin > {{ environmentName }}<strong *ngIf="isProduction"> > ACHTUNG LIVE-SYSTEM!</strong></span>
  </mat-toolbar-row>
</mat-toolbar>
<mat-sidenav-container>
  <mat-sidenav opened="true" mode="side" fixedInViewport="false">
    <mat-nav-list>
      <ng-container *ngIf="isLoggedIn$ | async; else showLogin">
        <a mat-list-item routerLink="profiles">
          <mat-icon mat-list-icon>person</mat-icon>
          Profile
        </a>
        <a mat-list-item routerLink="pages">
          <mat-icon mat-list-icon>contact_page</mat-icon>
          Seiten
        </a>
        <a mat-list-item routerLink="domains">
          <mat-icon mat-list-icon>public</mat-icon>
          Domains
        </a>
        <a mat-list-item routerLink="black-list-words">
          <mat-icon mat-list-icon>do_not_disturb_on</mat-icon>
          Blacklist Wörter
        </a>
        <a mat-list-item routerLink="templates">
          <mat-icon mat-list-icon>extension</mat-icon>
          Templates
        </a>
        <mat-divider></mat-divider>
        <a mat-list-item (click)="logout()">
          <mat-icon mat-list-icon>logout</mat-icon>
          Logout
        </a>
      </ng-container>
      <ng-template #showLogin>
        <a mat-list-item routerLink="login">
          <mat-icon mat-list-icon>key</mat-icon>
          Login
        </a>
      </ng-template>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <section>
      <router-outlet></router-outlet>
    </section>
  </mat-sidenav-content>
</mat-sidenav-container>
