<mat-toolbar color="primary">
  <span>Domains</span>
</mat-toolbar>
<table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="ID">
      ID
    </th>
    <td mat-cell *matCellDef="let element" (click)="openDetails(element)"> {{element.id}} </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Name">
      Name
    </th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>

  <ng-container matColumnDef="createdAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Erstellt am">
      Erstellt am
    </th>
    <td mat-cell *matCellDef="let element"> {{element.createdAt | date:'medium'}} </td>
  </ng-container>

  <ng-container matColumnDef="modifiedAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Geändert am">
      Geändert am
    </th>
    <td mat-cell *matCellDef="let element"> {{element.modifiedAt | date:'medium'}} </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>
      Aktionen
    </th>
    <td mat-cell *matCellDef="let element">
      n/a
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator [pageSizeOptions]="[20, 50, 100]"
               showFirstLastButtons
               aria-label="Seite auswählen">
</mat-paginator>

<div class="space-top">
  <h2>Neuer Eintrag</h2>
  <app-domains-details-page (onSuccess)="initializeData()"></app-domains-details-page>
</div>
