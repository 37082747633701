<mat-toolbar color="primary">
  <span>Profile</span>
</mat-toolbar>
<table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="ID">
      ID
    </th>
    <td mat-cell *matCellDef="let element" (click)="openDetails(element)"> {{element.id}} </td>
  </ng-container>

  <ng-container matColumnDef="firstname">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Vorname">
      {{ 'profile.firstname' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{element.firstname}} </td>
  </ng-container>

  <ng-container matColumnDef="lastname">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Vorname">
      {{ 'profile.lastname' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{element.lastname}} </td>
  </ng-container>

  <ng-container matColumnDef="contentTitle">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Titel">
      {{ 'profile.contentTitle' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{element.contentTitle}} </td>
  </ng-container>

  <ng-container matColumnDef="contentLeadtext">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Leadtext">
      {{ 'profile.contentLeadtext' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{element.contentLeadtext}} </td>
  </ng-container>

  <ng-container matColumnDef="pages">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Seite(n)">
      Seite(n)
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-chip-list aria-label="Seite">
        <ng-container *ngFor="let page of element.pages">
          <a [href]="'https://' + page.domain.name + '/' + page.name" target="_blank" style="text-decoration: none;">
            <mat-chip>
              {{ page.domain.name + '/' + page.name }}
              <button matChipRemove routerLink="/pages/{{page.id}}">
                <mat-icon>edit</mat-icon>
              </button>
              <button matChipRemove (click)="deletePage(page)">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
          </a>
        </ng-container>
        <a [routerLink]="['/pages/new']" [queryParams]="{profileId: element.id}">
          <mat-chip>
            <mat-icon>add</mat-icon>
          </mat-chip>
        </a>
      </mat-chip-list>
    </td>
  </ng-container>

  <ng-container matColumnDef="contentImageId">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Bild">
      Bild
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-icon color="success" *ngIf="element.contentImageId; else missingImage">done</mat-icon>
      <ng-template #missingImage>
        <mat-icon color="warn">close</mat-icon>
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="contentVideoId">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Video">
      Video
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-icon color="success" *ngIf="element.contentVideoId; else missingVideo;">done</mat-icon>
      <ng-template #missingVideo>
        <mat-icon color="warn">close</mat-icon>
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="wpUserId">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Wordpress User ID">
      Wordpress User ID
    </th>
    <td mat-cell *matCellDef="let element"> {{element.wpUserId}} </td>
  </ng-container>

  <ng-container matColumnDef="createdAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Erstellt am">
      Erstellt am
    </th>
    <td mat-cell *matCellDef="let element"> {{element.createdAt | date:'medium'}} </td>
  </ng-container>

  <ng-container matColumnDef="modifiedAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Geändert am">
      Geändert am
    </th>
    <td mat-cell *matCellDef="let element"> {{element.modifiedAt | date:'medium'}} </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>
      Aktionen
    </th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button color="warn" title="Löschen" (click)="$event.preventDefault(); delete(element)">
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator [pageSizeOptions]="[20, 50, 100]"
               showFirstLastButtons
               aria-label="Seite auswählen">
</mat-paginator>

<div class="space-top">
  <h2>Neuer Eintrag</h2>
  <app-profiles-details-page (onSuccess)="initializeData()"></app-profiles-details-page>
</div>
