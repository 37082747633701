<mat-card>
  <h2>Konfiguration</h2>
  <form [formGroup]="formGroup" (ngSubmit)="save()" novalidate>
    <mypb-autocomplete-profiles [selectedId]="formGroup.controls['profileId'].value"
                                (selected)="setProfile($event)"></mypb-autocomplete-profiles>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'page.name' | translate }}</mat-label>
      <input matInput placeholder="{{ 'page.name' | translate }}" formControlName="name">
      <mat-hint *ngIf="formGroup.controls['name'].touched && formGroup.controls['name'].hasError('name')">
        {{ 'page.name' | translate }} {{ 'global.ui.isRequired' | translate }}
      </mat-hint>
    </mat-form-field>
    <mypb-autocomplete-domains [selectedId]="formGroup.controls['domainId'].value"
                               (selected)="setDomain($event)"></mypb-autocomplete-domains>
    <mypb-autocomplete-templates [selectedId]="formGroup.controls['templateId'].value"
                                 (selected)="setTemplate($event)"></mypb-autocomplete-templates>
    <mat-card-actions>
      <button type="submit"
              mat-raised-button color="primary"
              [disabled]="!formGroup.valid">
        {{ 'global.ui.save' | translate }}
      </button>
    </mat-card-actions>
  </form>
</mat-card>
<br>
<mat-card *ngIf="page">
  <h2>Blöcke</h2>
  <app-block-manager [page]="page"></app-block-manager>
</mat-card>
