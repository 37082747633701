<mat-card class="example-card">
  <mat-card-title>Login</mat-card-title>
  <mat-card-content>
    <p>Bitte mit einem Admin-Account anmelden.</p>
    <mat-divider></mat-divider>
  </mat-card-content>
  <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate>
    <mat-form-field appearance="fill">
      <input matInput placeholder="{{ 'login.username' | translate }}" formControlName="username">
      <mat-hint *ngIf="loginForm.controls['username'].touched && loginForm.controls['username'].hasError('required')">
        {{ 'login.username.required' | translate }}
      </mat-hint>
      <mat-icon matSuffix>person</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <input matInput placeholder="{{ 'login.password' | translate }}" formControlName="password" type="password">
      <mat-hint *ngIf="loginForm.controls['username'].touched && loginForm.controls['username'].hasError('required')">
        {{ 'login.password.required' | translate }}
      </mat-hint>
      <mat-icon matSuffix>key</mat-icon>
    </mat-form-field>
    <mat-card-actions>
      <button type="submit"
              mat-raised-button color="primary"
              [disabled]="!loginForm.valid">
        {{ 'login.submit' | translate }}
      </button>
    </mat-card-actions>
  </form>
</mat-card>
