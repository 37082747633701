import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiModule } from './_generated/mypagebuilder-rest-api';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as moment from 'moment';
import { ApiTokenInterceptor } from './_common/api-token.interceptor';
import { ApiJsonInterceptor } from './_common/api-json.interceptor';
import { ApiErrorInterceptor } from './_common/api-error.interceptor';
import { DATE_PIPE_DEFAULT_TIMEZONE } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppState } from './state/app/app.state';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CdkMenuModule } from '@angular/cdk/menu';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DomainsPageComponent } from './pages/domains/domains-page.component';
import { TemplatesPageComponent } from './pages/templates/templates-page.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BlackListWordsComponent } from './pages/black-list-words/black-list-words-page.component';
import {
  BlackListWordsDetailsComponent,
} from './pages/black-list-words/black-list-words-details/black-list-words-details-page.component';
import '@angular/common/locales/global/de-CH';
import { DomainsDetailsPageComponent } from './pages/domains/domains-details/domains-details-page.component';
import { TemplatesDetailsPageComponent } from './pages/templates/templates-details/templates-details-page.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ProfilesPageComponent } from './pages/profiles/profiles-page.component';
import { ProfilesDetailsPageComponent } from './pages/profiles/profiles-details/profiles-details-page.component';
import { MatChipsModule } from '@angular/material/chips';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { UploadProfileVideoComponent } from './components/upload-profile-video/upload-profile-video.component';
import { PagesPageComponent } from './pages/pages/pages-page.component';
import { PagesDetailsPageComponent } from './pages/pages/pages-details/pages-details-page.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AutocompleteProfilesComponent } from './components/autocomplete-profiles/autocomplete-profiles.component';
import { AutocompleteDomainsComponent } from './components/autocomplete-domains/autocomplete-domains.component';
import { AutocompleteTemplatesComponent } from './components/autocomplete-templates/autocomplete-templates.component';
import { AutocompleteWpUsersComponent } from './components/autocomplete-wp-users/autocomplete-wp-users.component';
import { BlockManagerComponent } from './components/block-manager/block-manager.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  AutocompleteBlockTypesComponent,
} from './components/autocomplete-block-types/autocomplete-block-types.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const timezoneFactory = () => {
  const isSommerzeit = moment().isDST();
  return isSommerzeit ? 'CEST' : 'CET';
};

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    UploadImageComponent,
    UploadProfileVideoComponent,
    AutocompleteProfilesComponent,
    AutocompleteDomainsComponent,
    AutocompleteTemplatesComponent,
    AutocompleteWpUsersComponent,
    AutocompleteBlockTypesComponent,
    DomainsPageComponent,
    DomainsDetailsPageComponent,
    TemplatesPageComponent,
    TemplatesDetailsPageComponent,
    BlackListWordsComponent,
    BlackListWordsDetailsComponent,
    ProfilesPageComponent,
    ProfilesDetailsPageComponent,
    PagesPageComponent,
    PagesDetailsPageComponent,
    BlockManagerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ApiModule.forRoot({ rootUrl: environment.apiConfiguration.baseUrl }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
      defaultLanguage: 'de',
    }),
    NgxsModule.forRoot([
      AppState,
    ], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      storage: StorageOption.LocalStorage,
      key: ['MYPB__ADMIN__AppState'],
    }),
    NgxsResetPluginModule.forRoot(),
    SweetAlert2Module.forRoot(),
    BrowserAnimationsModule,
    NgxDropzoneModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    CdkMenuModule,
    MatListModule,
    MatSidenavModule,
    MatCardModule,
    MatButtonModule,
    MatProgressBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatToolbarModule,
    MatChipsModule,
    MatAutocompleteModule,
    DragDropModule,
    MatCheckboxModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiJsonInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiErrorInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-CH',
    },
    {
      provide: DATE_PIPE_DEFAULT_TIMEZONE,
      useFactory: timezoneFactory,
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'CHF',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
