import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MypbAdminProfilesService, MypbProfile } from '../../_generated/mypagebuilder-rest-api';
import { FormControl } from '@angular/forms';
import { Observable, startWith } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'mypb-autocomplete-profiles',
  templateUrl: './autocomplete-profiles.component.html',
  styleUrls: ['autocomplete-profiles.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class AutocompleteProfilesComponent implements OnInit {
  @Input() selectedId = '';
  @Output() selected: EventEmitter<MypbProfile> = new EventEmitter<MypbProfile>();
  myControl = new FormControl<string | MypbProfile>('');
  options: MypbProfile[] = [];
  filteredOptions: Observable<MypbProfile[]>;

  constructor(
    private mypbAdminProfilesService: MypbAdminProfilesService,
  ) {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.lastname;
        if (typeof value !== 'string') {
          this.selected.emit(value as MypbProfile);
        }
        return name ? this._filter(name as string) : this.options.slice();
      }),
    );
  }

  ngOnInit() {
    this.mypbAdminProfilesService.profilesAdminControllerFindAll()
      .subscribe({
        next: value => {
          this.options = value;
          if (this.options && this.options.length === 1) {
            this.selectedId = this.options[0].id || '';
          }
          this.checkForSelectedId();
        },
      });
  }

  ngOnChanges() {
    this.checkForSelectedId();
  }

  displayFn(entity: MypbProfile): string {
    return entity && entity.firstname && entity.lastname ? entity.firstname + ' ' + entity.lastname : '';
  }

  private _filter(searchString: string): MypbProfile[] {
    const filterValue = searchString.toLowerCase();
    return this.options.filter(option => option.lastname.toLowerCase().includes(filterValue));
  }

  /**
   * Checks for selected ID and sets it in the form if available/feasible.
   *
   * @private
   */
  private checkForSelectedId() {
    if (this.selectedId) {
      const selected = this.options.find(option => option.id === this.selectedId);
      if (selected) {
        this.myControl.setValue(selected);
        this.selected.emit(selected);
      }
    }
  }
}
