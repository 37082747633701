import { Injectable } from '@angular/core';
import { Action, NgxsAfterBootstrap, Selector, State, StateContext } from '@ngxs/store';
import { AppStateModel } from './app.state.model';
import { AppStateSetAuthentication, AppStateSetProfile, AppStateSetTechnicalProfile } from './app.actions';
import { MypbAuthenticationService, MypbProfile, MypbProfileDto } from '../../_generated/mypagebuilder-rest-api';
import { StateResetAll } from 'ngxs-reset-plugin';

const STATE_NAME = 'MYPB__ADMIN__AppState';
const DEFAULT_STATE: AppStateModel = {
  authentication: {
    accessToken: '',
    refreshToken: '',
  },
  technicalProfile: undefined,
  profile: undefined,
};

@State<AppStateModel>({
  name: STATE_NAME,
  defaults: DEFAULT_STATE,
})
@Injectable()
export class AppState implements NgxsAfterBootstrap {
  constructor(
    private mypbAuthenticationService: MypbAuthenticationService,
  ) {
  }

  @Selector()
  static isLoggedIn(state: AppStateModel): boolean {
    return !!(state.authentication && state.authentication.accessToken);
  }

  @Selector()
  static refreshToken(state: AppStateModel): string {
    return state.authentication.refreshToken;
  }

  @Selector()
  static accessToken(state: AppStateModel): string {
    return state.authentication.accessToken;
  }

  @Selector()
  static technicalProfile(state: AppStateModel): MypbProfileDto | undefined {
    return state.technicalProfile;
  }

  @Selector()
  static profile(state: AppStateModel): MypbProfile | undefined {
    return state.profile;
  }

  @Action(AppStateSetAuthentication)
  appStateSetAuthentication(ctx: StateContext<AppStateModel>, action: AppStateSetAuthentication) {
    ctx.patchState({
      authentication: {
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
      },
    });
    return this.loadAndSetProfile(ctx);
  }

  @Action(AppStateSetTechnicalProfile)
  appStateSetTechnicalProfile(ctx: StateContext<AppStateModel>, action: AppStateSetTechnicalProfile) {
    return ctx.patchState({
      technicalProfile: action.technicalProfile,
    });
  }

  @Action(AppStateSetProfile)
  appStateSetProfile(ctx: StateContext<AppStateModel>, action: AppStateSetProfile) {
    return ctx.patchState({
      profile: action.profile,
    });
  }

  ngxsAfterBootstrap(ctx: StateContext<AppStateModel>) {
    this.loadAndSetProfile(ctx);
  }

  private loadAndSetProfile(ctx: StateContext<AppStateModel>) {
    if (ctx.getState().authentication.accessToken) {
      this.mypbAuthenticationService.authenticationControllerProfile().subscribe({
        next: technicalProfile => {
          if (technicalProfile && technicalProfile.meta.isAdmin) {
            ctx.dispatch(new AppStateSetTechnicalProfile(technicalProfile));
          } else {
            ctx.dispatch(new StateResetAll());
          }
        },
      });
    }
  }
}
