import { GLOBALS } from './global.environment';

export const environment = GLOBALS;

// Overwrite settings for this environment.
environment.name = 'test';
environment.apiConfiguration = {
  baseUrl: 'https://test-api.mypagebuilder.ch',
};
environment.isLoggingEnabled.ngxs = true;
environment.isLoggingEnabled.logger = true;
environment.production = false;
