import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { LoggerService } from '../../_common/logger/logger.service';
import { MypbAdminImagesService, MypbImage } from '../../_generated/mypagebuilder-rest-api';
import swal from 'sweetalert2';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'mypb-upload-image',
  templateUrl: './upload-image.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class UploadImageComponent implements OnInit {
  @Input() autoUpload = false;
  @Input() allowMultiple = false;
  @Input() profileId = '';
  @Output() uploadedImage: EventEmitter<MypbImage> = new EventEmitter<MypbImage>();
  @Output() uploadedImages: EventEmitter<MypbImage[]> = new EventEmitter<MypbImage[]>();
  public filesForImage: File[] = [];
  public maxFileSizeInMegaBytes = environment.uploadMaxImageFilesizeInMegabytes;

  constructor(
    private mypbAdminImagesService: MypbAdminImagesService,
  ) {
  }

  ngOnInit() {
  }

  public onSelectImages(event: any) {
    for (const rejectedFile of event.rejectedFiles) {
      const baseMessage = `Das Bild ${rejectedFile.name} konnte nicht hochgeladen werden`;
      switch (rejectedFile.reason) {
        case 'size':
          swal.fire('Oops!', `${baseMessage} weil es zu gross ist (maximal ${this.maxFileSizeInMegaBytes} MegaBytes).`, 'error');
          break;
      }
    }
    this.filesForImage = event.addedFiles;
    if (this.autoUpload) {
      this.saveImages();
    }
  }

  public saveImages() {
    for (const fileForImage of this.filesForImage) {
      this.saveImage(fileForImage);
    }
  }

  public onRemoveProfileImage(fileForImage: File) {
    this.filesForImage.splice(this.filesForImage.indexOf(fileForImage), 1);
  }

  private saveImage(fileForImage: File) {
    if (fileForImage && this.profileId) {
      this.mypbAdminImagesService.imagesAdminControllerCreate({
        body: {
          file: fileForImage,
          profileId: this.profileId,
        },
      }).subscribe({
        next: imageUploadedResponse => {
          LoggerService.LOG(this, 'imageUploadedResponse', imageUploadedResponse);
          this.uploadedImage.emit(imageUploadedResponse);
          this.onRemoveProfileImage(fileForImage);
        },
        error: imageUploadException => {
          LoggerService.ERROR(this, 'imageUploadException', imageUploadException);
        },
      });
    }
  }
}
