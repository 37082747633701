/**
 * Local Logger Model.
 */
export interface LoggerModel {
  /**
   * The date and time of the logger entry.
   */
  datetime: Date;
  /**
   * Class name where the logger entry stems from.
   */
  className: any;
  /**
   * Just a message / text, usually written by the developers.
   */
  text?: string;
  /**
   * The actual exception, can be a string, a stack trace, object etc.
   */
  stack?: any;
}

/**
 * Enums for Logger Type.
 */
export enum LoggerType {
  LOG = <any>'LOG',
  ERROR = <any>'ERROR',
}
