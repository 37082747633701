<mat-card class="example-card">
  <form [formGroup]="formGroup" (ngSubmit)="save()" novalidate>
    <mat-form-field appearance="fill">
      <input matInput placeholder="{{ 'profile.firstname' | translate }}" formControlName="firstname">
      <mat-hint
        *ngIf="formGroup.controls['firstname'].touched && formGroup.controls['firstname'].hasError('firstname')">
        {{ 'profile.firstname' | translate }} {{ 'global.ui.isRequired' | translate }}
      </mat-hint>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <input matInput placeholder="{{ 'profile.lastname' | translate }}" formControlName="lastname">
      <mat-hint *ngIf="formGroup.controls['lastname'].touched && formGroup.controls['lastname'].hasError('lastname')">
        {{ 'profile.lastname' | translate }} {{ 'global.ui.isRequired' | translate }}
      </mat-hint>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <input matInput placeholder="{{ 'profile.contentTitle' | translate }}" formControlName="contentTitle">
      <mat-hint
        *ngIf="formGroup.controls['contentTitle'].touched && formGroup.controls['contentTitle'].hasError('contentTitle')">
        {{ 'profile.contentTitle' | translate }} {{ 'global.ui.isRequired' | translate }}
      </mat-hint>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <input matInput placeholder="{{ 'profile.contentLeadtext' | translate }}" formControlName="contentLeadtext">
      <mat-hint
        *ngIf="formGroup.controls['contentLeadtext'].touched && formGroup.controls['contentLeadtext'].hasError('contentLeadtext')">
        {{ 'profile.contentLeadtext' | translate }} {{ 'global.ui.isRequired' | translate }}
      </mat-hint>
    </mat-form-field>

    <mypb-autocomplete-wp-users [selectedId]="formGroup.controls['wpUserId'].value"
                                (selected)="setWpUser($event)"></mypb-autocomplete-wp-users>

    <div class="space-top" *ngIf="formGroup.controls['id'].value">
      <h2>Bild</h2>
      <mypb-upload-image [autoUpload]="true"
                         [profileId]="formGroup.controls['id'].value"
                         (uploadedImage)="onImageUploaded($event)"></mypb-upload-image>
    </div>

    <div class="space-top" *ngIf="formGroup.controls['id'].value">
      <h2>Video</h2>
      <mypb-upload-profile-video [profileId]="formGroup.controls['id'].value"
                                 (uploadedVideo)="onVideoUploaded($event)"></mypb-upload-profile-video>
    </div>

    <mat-card-actions>
      <button type="submit"
              mat-raised-button color="primary"
              [disabled]="!formGroup.valid">
        {{ 'global.ui.save' | translate }}
      </button>
    </mat-card-actions>
  </form>
</mat-card>
