/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { MypbApiService } from './services/mypb-api.service';
import { MypbContentDeliveryCountriesService } from './services/mypb-content-delivery-countries.service';
import { MypbContentDeliveryDomainsService } from './services/mypb-content-delivery-domains.service';
import { MypbContentDeliveryPagesService } from './services/mypb-content-delivery-pages.service';
import { MypbContentDeliveryProfilesService } from './services/mypb-content-delivery-profiles.service';
import { MypbContentManagementAddressesService } from './services/mypb-content-management-addresses.service';
import { MypbContentManagementBlocksService } from './services/mypb-content-management-blocks.service';
import { MypbContentManagementEmailAddressesService } from './services/mypb-content-management-email-addresses.service';
import { MypbContentManagementImagesService } from './services/mypb-content-management-images.service';
import { MypbContentManagementPagesService } from './services/mypb-content-management-pages.service';
import { MypbContentManagementPhoneNumbersService } from './services/mypb-content-management-phone-numbers.service';
import { MypbContentManagementProfilesService } from './services/mypb-content-management-profiles.service';
import { MypbContentManagementVideosService } from './services/mypb-content-management-videos.service';
import { MypbContentManagementLinksService } from './services/mypb-content-management-links.service';
import { MypbContentManagementAnalyticsService } from './services/mypb-content-management-analytics.service';
import { MypbAdminBlackListWordsService } from './services/mypb-admin-black-list-words.service';
import { MypbAdminDomainsService } from './services/mypb-admin-domains.service';
import { MypbAdminTemplatesService } from './services/mypb-admin-templates.service';
import { MypbAdminProfilesService } from './services/mypb-admin-profiles.service';
import { MypbAdminImagesService } from './services/mypb-admin-images.service';
import { MypbAdminVideosService } from './services/mypb-admin-videos.service';
import { MypbAdminPagesService } from './services/mypb-admin-pages.service';
import { MypbAdminWpUsersService } from './services/mypb-admin-wp-users.service';
import { MypbAdminBlocksService } from './services/mypb-admin-blocks.service';
import { MypbAuthenticationService } from './services/mypb-authentication.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    MypbApiService,
    MypbContentDeliveryCountriesService,
    MypbContentDeliveryDomainsService,
    MypbContentDeliveryPagesService,
    MypbContentDeliveryProfilesService,
    MypbContentManagementAddressesService,
    MypbContentManagementBlocksService,
    MypbContentManagementEmailAddressesService,
    MypbContentManagementImagesService,
    MypbContentManagementPagesService,
    MypbContentManagementPhoneNumbersService,
    MypbContentManagementProfilesService,
    MypbContentManagementVideosService,
    MypbContentManagementLinksService,
    MypbContentManagementAnalyticsService,
    MypbAdminBlackListWordsService,
    MypbAdminDomainsService,
    MypbAdminTemplatesService,
    MypbAdminProfilesService,
    MypbAdminImagesService,
    MypbAdminVideosService,
    MypbAdminPagesService,
    MypbAdminWpUsersService,
    MypbAdminBlocksService,
    MypbAuthenticationService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
