import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { MypbAdminDomainsService, MypbDomain } from '../../../_generated/mypagebuilder-rest-api';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import swal from 'sweetalert2';

@Component({
  selector: 'app-domains-details-page',
  templateUrl: './domains-details-page.component.html',
  styleUrls: ['./domains-details-page.component.scss'],
})
export class DomainsDetailsPageComponent {
  @Output() onSuccess: EventEmitter<void> = new EventEmitter<void>();
  public formGroup: FormGroup;

  constructor(
    private readonly store: Store,
    private readonly mypbAdminDomainsService: MypbAdminDomainsService,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.formGroup = new FormGroup({
      id: new FormControl(null, []),
      createdAt: new FormControl(null, []),
      modifiedAt: new FormControl(null, []),
      name: new FormControl('', [Validators.required]),
      isActive: new FormControl(true, []),
    });
  }

  ngAfterViewInit() {
    if (this.activatedRoute.snapshot.params['id']) {
      this.mypbAdminDomainsService
        .domainsAdminControllerFindOne({
          id: this.activatedRoute.snapshot.params['id'],
        })
        .subscribe(value => {
          this.formGroup.setValue(value);
        });
    }
  }

  /**
   * Creating/updating entry when clicking the save button.
   */
  public save() {
    if (this.activatedRoute.snapshot.params['id']) {

    } else {
      this.create().subscribe({
        next: this.next,
        error: this.error,
      });
    }
  }

  /**
   * Just the API call to create a new entry.
   *
   * @private
   */
  private create() {
    return this.mypbAdminDomainsService.domainsAdminControllerCreate({
      body: this.formGroup.value,
    });
  }

  /**
   * Fired after successfully saving the entry (applies to both created or updated).
   *
   * @param response
   */
  private next = (response: MypbDomain) => {
    swal.fire('Gespeichert!', '', 'success');
    this.onSuccess.emit();
  };

  /**
   * Fired after error during saving the entry (applies to both created or updated).
   */
  private error = () => {
    swal.fire('Oops', 'Beim speichern ist ein Fehler aufgetreten.', 'error');
  };
}
