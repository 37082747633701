import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { DomainsPageComponent } from './pages/domains/domains-page.component';
import { TemplatesPageComponent } from './pages/templates/templates-page.component';
import { BlackListWordsComponent } from './pages/black-list-words/black-list-words-page.component';
import {
  BlackListWordsDetailsComponent,
} from './pages/black-list-words/black-list-words-details/black-list-words-details-page.component';
import { DomainsDetailsPageComponent } from './pages/domains/domains-details/domains-details-page.component';
import { TemplatesDetailsPageComponent } from './pages/templates/templates-details/templates-details-page.component';
import { ProfilesPageComponent } from './pages/profiles/profiles-page.component';
import { ProfilesDetailsPageComponent } from './pages/profiles/profiles-details/profiles-details-page.component';
import { PagesPageComponent } from './pages/pages/pages-page.component';
import { PagesDetailsPageComponent } from './pages/pages/pages-details/pages-details-page.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginPageComponent,
  },
  {
    path: 'domains',
    children: [
      {
        path: '',
        component: DomainsPageComponent,
      },
      {
        path: 'new',
        component: DomainsDetailsPageComponent,
      },
      {
        path: ':id',
        component: DomainsDetailsPageComponent,
      },
    ],
  },
  {
    path: 'templates',
    children: [
      {
        path: '',
        component: TemplatesPageComponent,
      },
      {
        path: 'new',
        component: TemplatesDetailsPageComponent,
      },
      {
        path: ':id',
        component: TemplatesDetailsPageComponent,
      },
    ],
  },
  {
    path: 'black-list-words',
    children: [
      {
        path: '',
        component: BlackListWordsComponent,
      },
      {
        path: 'new',
        component: BlackListWordsDetailsComponent,
      },
      {
        path: ':id',
        component: BlackListWordsDetailsComponent,
      },
    ],
  },
  {
    path: 'profiles',
    children: [
      {
        path: '',
        component: ProfilesPageComponent,
      },
      {
        path: 'new',
        component: ProfilesDetailsPageComponent,
      },
      {
        path: ':id',
        component: ProfilesDetailsPageComponent,
      },
    ],
  },
  {
    path: 'pages',
    children: [
      {
        path: '',
        component: PagesPageComponent,
      },
      {
        path: 'new',
        component: PagesDetailsPageComponent,
      },
      {
        path: ':id',
        component: PagesDetailsPageComponent,
      },
    ],
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
