import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { MypbAdminTemplatesService, MypbTemplate } from '../../../_generated/mypagebuilder-rest-api';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import swal from 'sweetalert2';

@Component({
  selector: 'app-templates-details-page',
  templateUrl: './templates-details-page.component.html',
  styleUrls: ['./templates-details-page.component.scss'],
})
export class TemplatesDetailsPageComponent {
  @Output() onSuccess: EventEmitter<void> = new EventEmitter<void>();
  public formGroup: FormGroup;

  constructor(
    private readonly store: Store,
    private readonly mypbAdminTemplatesService: MypbAdminTemplatesService,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.formGroup = new FormGroup({
      id: new FormControl('', []),
      createdAt: new FormControl('', []),
      modifiedAt: new FormControl('', []),
      isActive: new FormControl(true, []),
      name: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      technicalName: new FormControl('', [Validators.required]),
    });
  }

  ngAfterViewInit() {
    if (this.activatedRoute.snapshot.params['id']) {
      this.mypbAdminTemplatesService
        .templatesAdminControllerFindOne({
          id: this.activatedRoute.snapshot.params['id'],
        })
        .subscribe(value => {
          this.formGroup.setValue(value);
        });
    }
  }

  /**
   * Creating/updating entry when clicking the save button.
   */
  public save() {
    if (this.activatedRoute.snapshot.params['id']) {
      this.update().subscribe({
        next: this.next,
        error: this.error,
      });
    } else {
      this.create().subscribe({
        next: this.next,
        error: this.error,
      });
    }
  }

  /**
   * Just the API call to update an existing entry.
   *
   * @private
   */
  private update() {
    return this.mypbAdminTemplatesService.templatesAdminControllerUpdate({
      id: this.formGroup.value.id as string,
      body: this.formGroup.value,
    });
  }

  /**
   * Just the API call to create a new entry.
   *
   * @private
   */
  private create() {
    return this.mypbAdminTemplatesService.templatesAdminControllerCreate({
      body: this.formGroup.value,
    });
  }

  /**
   * Fired after successfully saving the entry (applies to both created or updated).
   *
   * @param response
   */
  private next = (response: MypbTemplate) => {
    swal.fire('Gespeichert!', '', 'success');
    this.onSuccess.emit();
  };

  /**
   * Fired after error during saving the entry (applies to both created or updated).
   */
  private error = () => {
    swal.fire('Oops', 'Beim speichern ist ein Fehler aufgetreten.', 'error');
  };
}
