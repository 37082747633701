import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MypbAuthenticationService } from '../../_generated/mypagebuilder-rest-api';
import swal from 'sweetalert2';
import { LoggerService } from '../../_common/logger/logger.service';
import { Store } from '@ngxs/store';
import { AppStateSetAuthentication } from '../../state/app/app.actions';
import { Router } from '@angular/router';
import { AppState } from '../../state/app/app.state';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  public loginForm: FormGroup;
  private loginSubscription$: Subscription | undefined;

  constructor(
    private readonly store: Store,
    private readonly mypbAuthenticationService: MypbAuthenticationService,
    private readonly router: Router,
  ) {
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    if (this.store.selectSnapshot(AppState.isLoggedIn)) {
      this.router.navigateByUrl('/profiles').then();
    }
  }

  ngOnDestroy(): void {
    if (this.loginSubscription$) {
      this.loginSubscription$.unsubscribe();
    }
  }

  async login() {
    if (this.loginForm.valid) {
      this.loginSubscription$ = this.mypbAuthenticationService.authenticationControllerLogin({
        body: {
          username: this.loginForm.value.username,
          password: this.loginForm.value.password,
        },
      }).subscribe({
        next: async value => {
          await this.store.dispatch(new AppStateSetAuthentication(value.access_token, value.refresh_token));
          this.router.navigateByUrl('/profiles').then();
          swal.fire('Willkommen!', 'Sie wurden erfolgreich eingeloggt.', 'success');

        },
        error: loginException => {
          LoggerService.ERROR(this, 'loginException', loginException);
          swal.fire('Oops!', 'Der Benutzername oder das Passwort ist falsch.', 'error');
        },
      });
    }
  }
}
