<mat-card class="example-card">
  <form [formGroup]="formGroup" (ngSubmit)="save()" novalidate>
    <mat-form-field appearance="fill">
      <input matInput placeholder="{{ 'domain.name' | translate }}" formControlName="name">
      <mat-hint *ngIf="formGroup.controls['name'].touched && formGroup.controls['name'].hasError('name')">
        {{ 'domain.name' | translate }} {{ 'global.ui.isRequired' | translate }}
      </mat-hint>
    </mat-form-field>
    <mat-card-actions>
      <button type="submit"
              mat-raised-button color="primary"
              [disabled]="!formGroup.valid">
        {{ 'global.ui.save' | translate }}
      </button>
    </mat-card-actions>
  </form>
</mat-card>
