<div class="mypb-upload-image">
  <ngx-dropzone (change)="onSelectImages($event)"
                [multiple]="allowMultiple"
                accept="image/jpeg,image/jpg,image/png"
                [maxFileSize]="maxFileSizeInMegaBytes * 1024 * 1024">
    <ngx-dropzone-label>Hier klicken oder Bild hierhin ziehen</ngx-dropzone-label>
    <ng-container *ngFor="let fileForImage of filesForImage">
      <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                                  [file]="fileForImage"
                                  [removable]="true"
                                  (removed)="onRemoveProfileImage(fileForImage)">
        <ngx-dropzone-label>{{ fileForImage.name }} ({{ fileForImage.type }})</ngx-dropzone-label>
      </ngx-dropzone-image-preview>
    </ng-container>
  </ngx-dropzone>
  <ng-container *ngIf="!autoUpload">
    <button class="btn btn-primary"
            (click)="saveImages()"
            [disabled]="!filesForImage.length">
      {{ 'global.ui.save' | translate }}
    </button>
  </ng-container>
</div>
