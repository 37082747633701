import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MypbAdminPagesService, MypbPage } from '../../_generated/mypagebuilder-rest-api';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pages-page',
  templateUrl: './pages-page.component.html',
  styleUrls: ['./pages-page.component.scss'],
})
export class PagesPageComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort | undefined;
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  public displayedColumns: string[] = ['id', 'name', 'profile', 'domain', 'template', 'createdAt', 'modifiedAt', 'actions'];
  public dataSource = new MatTableDataSource<MypbPage>();

  constructor(
    private readonly store: Store,
    private readonly mypbAdminPagesService: MypbAdminPagesService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.initializeData();
  }

  ngOnDestroy(): void {
  }

  public initializeData() {
    this.mypbAdminPagesService.pagesAdminControllerFindAll()
      .subscribe(result => {
        this.dataSource = new MatTableDataSource(result);
        if (this.sort) {
          this.dataSource.sort = this.sort;
        }
        if (this.paginator) {
          this.dataSource.paginator = this.paginator;
        }
      });
  }

  openDetails(row: any) {
    this.router.navigate([row.id], { relativeTo: this.activatedRoute }).then();
  }

  delete(row: any) {
    this.mypbAdminPagesService.pagesAdminControllerDelete({
      id: row.id,
    }).subscribe({
      next: () => {
        this.initializeData();
      },
    });
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
    } else {
    }
  }
}
