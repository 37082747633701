import { MypbProfile, MypbProfileDto } from '../../_generated/mypagebuilder-rest-api';

export class AppStateSetAuthentication {
  static readonly type = '[app] Set authentication';

  constructor(public accessToken: string, public refreshToken: string) {
  }
}

export class AppStateSetTechnicalProfile {
  static readonly type = '[app] Set technical profile';

  constructor(public technicalProfile: MypbProfileDto) {
  }
}

export class AppStateSetProfile {
  static readonly type = '[app] Set profile';

  constructor(public profile: MypbProfile) {
  }
}

export class AppStateSetDomainAndPage {
  static readonly type = '[app] Set domain and page';

  constructor(public domainName: string, public domainId: string, public pageName: string, public pageId: string) {
  }
}

export class AppStateToggleEditMode {
  static readonly type = '[app] Toggle edit mode';
}

export class AppStateRefreshPageData {
  static readonly type = '[app] Refresh page data';
}
