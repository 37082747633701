/**
 * This global environment variables are equal to production.
 * The GLOBAL constant shouldn't be imported directly. Only import them via an environment variable.
 */
export const GLOBALS = {
  name: 'production',

  /**
   * prod, abna, entw, qa etc. Can be used to implement specific functionality for dedicated environments.
   */
  apiConfiguration: {
    baseUrl: 'https://api.mypagebuilder.ch',
  },

  /**
   * This is an ionic internal flag which states how to compile the app.
   */
  production: true,

  /**
   * Logging settings for NGXS and general.
   */
  isLoggingEnabled: {
    logger: false,
    ngxs: false,
  },

  /**
   * Max file sizes for uplaod.
   */
  uploadMaxImageFilesizeInMegabytes: 25,
  uploadMaxVideoFilesizeInMegabytes: 100,
};
