<div class="mypb-upload-profile-video">
  <ngx-dropzone (change)="onSelectProfileVideo($event)"
                [multiple]="false"
                accept="video/mpeg,video/mp4,video/quicktime"
                [maxFileSize]="maxFileSizeInMegaBytes * 1024 * 1024">
    <ngx-dropzone-label>Hier klicken oder Video hierhin ziehen</ngx-dropzone-label>
    <ng-container *ngIf="fileForProfileVideo">
      <ngx-dropzone-video-preview ngProjectAs="ngx-dropzone-preview"
                                  [file]="fileForProfileVideo"
                                  [removable]="true"
                                  (removed)="onRemoveProfileVideo()">
        <ngx-dropzone-label>{{ fileForProfileVideo.name }} ({{ fileForProfileVideo.type }})</ngx-dropzone-label>
      </ngx-dropzone-video-preview>
    </ng-container>
  </ngx-dropzone>
  <button class="btn btn-primary"
          (click)="saveVideo()"
          [disabled]="!fileForProfileVideo || isUploading">
    {{ 'global.ui.save' | translate }}
    <i class="far fa-spinner-third fa-spin" *ngIf="isUploading"></i>
  </button>
  <p *ngIf="isUploading">
    <small>
      Das Hochladen des Videos kann einen Moment dauern, bitte haben Sie einen Moment Geduld.
    </small>
  </p>
</div>
