<div class="autocomplete-profiles">
  <mat-form-field appearance="fill" *ngIf="options && options.length">
    <mat-label>Wordpress User</mat-label>
    <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option.userEmail}} ({{option.displayName}})
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
