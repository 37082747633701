<div class="block-manager">
  <div cdkDropList (cdkDropListDropped)="drop($event)">
    <mat-card *ngFor="let block of page?.blocks" cdkDrag>
      <mat-card-title>{{ block.technicalName }}</mat-card-title>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'block.name' | translate }}</mat-label>
        <input matInput placeholder="{{ 'block.name' | translate }}" [(ngModel)]="block.name">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'block.slug' | translate }}</mat-label>
        <input matInput placeholder="{{ 'block.slug' | translate }}" [(ngModel)]="block.slug">
      </mat-form-field>
      <mat-card-content>
        <mat-checkbox [checked]="block.isActive"
                      (change)="block.isActive = $event.checked">
          Aktiv
        </mat-checkbox>
      </mat-card-content>
      <mat-card-actions>
        <button mat-icon-button color="primary" (click)="delete(block)">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
  <br>
  <button mat-raised-button color="primary" (click)="save()">Speichern</button>
  <br>
  <br>
  <mat-divider></mat-divider>
  <br>
  <h3>Neuer Block</h3>
  <form [formGroup]="formGroup" (ngSubmit)="create()" novalidate>
    <mypb-autocomplete-block-types (selected)="setBlocktype($event)"></mypb-autocomplete-block-types>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'block.name' | translate }}</mat-label>
      <input matInput placeholder="{{ 'block.name' | translate }}" formControlName="name">
      <mat-hint *ngIf="formGroup.controls['name'].touched && formGroup.controls['name'].hasError('name')">
        {{ 'block.name' | translate }} {{ 'global.ui.isRequired' | translate }}
      </mat-hint>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'block.slug' | translate }}</mat-label>
      <input matInput placeholder="{{ 'block.slug' | translate }}" formControlName="slug">
      <mat-hint *ngIf="formGroup.controls['slug'].touched && formGroup.controls['slug'].hasError('slug')">
        {{ 'block.slug' | translate }} {{ 'global.ui.isRequired' | translate }}
      </mat-hint>
    </mat-form-field>
    <div>
      <mat-checkbox formControlName="isActive">
        {{ 'block.isActive' | translate }}
      </mat-checkbox>
      <mat-hint *ngIf="formGroup.controls['isActive'].touched && formGroup.controls['isActive'].hasError('isActive')">
        {{ 'block.isActive' | translate }} {{ 'global.ui.isRequired' | translate }}
      </mat-hint>
    </div>
    <mat-card-actions>
      <button type="submit"
              mat-raised-button color="primary"
              [disabled]="!formGroup.valid">
        {{ 'global.ui.save' | translate }}
      </button>
    </mat-card-actions>
  </form>
</div>
