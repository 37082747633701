import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { LoggerService } from '../../_common/logger/logger.service';
import { MypbAdminVideosService, MypbVideo } from '../../_generated/mypagebuilder-rest-api';
import swal from 'sweetalert2';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'mypb-upload-profile-video',
  templateUrl: './upload-profile-video.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class UploadProfileVideoComponent implements OnInit {
  @Output() uploadedVideo: EventEmitter<MypbVideo> = new EventEmitter<MypbVideo>();
  @Input() profileId = '';
  public fileForProfileVideo: File | undefined;
  public maxFileSizeInMegaBytes = environment.uploadMaxVideoFilesizeInMegabytes;
  public isUploading = false;

  constructor(
    private mypbAdminVideosService: MypbAdminVideosService,
  ) {
  }

  ngOnInit() {
  }

  public saveVideo() {
    if (this.fileForProfileVideo && this.profileId) {
      this.isUploading = true;
      this.mypbAdminVideosService.videosAdminControllerCreate({
        body: {
          file: this.fileForProfileVideo,
          profileId: this.profileId,
        },
      }).subscribe({
        next: videoUploadedResponse => {
          LoggerService.LOG(this, 'videoUploadedResponse', videoUploadedResponse);
          swal.fire('Schön!', `Das Video wurde hochgeladen.`, 'success');
          this.uploadedVideo.emit(videoUploadedResponse);
          this.fileForProfileVideo = undefined;
          this.isUploading = false;
        },
        error: imageUploadException => {
          swal.fire('Oops!', `Da ging etwas schief beim Hochladen des Videos.`, 'error');
          LoggerService.ERROR(this, 'imageUploadException', imageUploadException);
          this.isUploading = false;
        },
      });
    }
  }


  public onRemoveProfileVideo() {
    this.fileForProfileVideo = undefined;
  }


  public onSelectProfileVideo(event: any) {
    for (const rejectedFile of event.rejectedFiles) {
      const baseMessage = `Das Video ${rejectedFile.name} konnte nicht hochgeladen werden`;
      switch (rejectedFile.reason) {
        case 'size':
          swal.fire('Oops!', `${baseMessage} weil es zu gross ist (maximal ${this.maxFileSizeInMegaBytes} MegaBytes).`, 'error');
          break;
      }
    }
    this.fileForProfileVideo = event.addedFiles[0];
  }
}
