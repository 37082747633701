import { Component, Input, OnInit } from '@angular/core';
import { MypbAdminBlocksService, MypbBlock, MypbBlockType, MypbPage } from '../../_generated/mypagebuilder-rest-api';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-block-manager',
  templateUrl: './block-manager.component.html',
  styleUrls: ['./block-manager.component.scss'],
})
export class BlockManagerComponent implements OnInit {
  @Input('page') page?: MypbPage;
  public formGroup: FormGroup;

  constructor(
    private mypbAdminBlocksService: MypbAdminBlocksService,
  ) {
    this.formGroup = new FormGroup({
      pageId: new FormControl('', []),
      name: new FormControl('', [Validators.required]),
      slug: new FormControl('', [Validators.required]),
      technicalName: new FormControl('', [Validators.required]),
      isActive: new FormControl(true, [Validators.required]),
    });
  }

  ngOnInit(): void {
  }

  public drop(event: CdkDragDrop<string[]>) {
    if (this.page?.blocks) {
      moveItemInArray(this.page?.blocks, event.previousIndex, event.currentIndex);
      this.updatePositions();
    }
  }

  public updatePositions() {
    if (this.page?.blocks) {
      for (let index = 0; index < this.page.blocks.length; index++) {
        this.page.blocks[index].position = index + 1;
      }
    }
  }

  public create() {
    if (this.page?.id) {
      this.formGroup.patchValue({
        pageId: this.page?.id,
      });
      this.mypbAdminBlocksService.blocksAdminControllerCreate({
        technicalName: this.formGroup.value.technicalName,
        body: this.formGroup.value,
      }).subscribe({
        next: block => {
          this.formGroup.reset();
          this.page?.blocks.push(block);
        },
      });
    }
  }

  public save() {
    if (this.page?.blocks) {
      for (const block of this.page?.blocks) {
        this.mypbAdminBlocksService.blocksAdminControllerUpdate({
          id: block.id ? block.id : '',
          technicalName: block.technicalName,
          body: block,
        }).subscribe();
      }
    }
  }

  public setBlocktype(entity: MypbBlockType) {
    console.debug('===entity', entity);
    if (entity.id) {
      this.formGroup.patchValue({
        technicalName: entity.technicalName,
      });
    }
  }

  public delete(entity: MypbBlock) {
    if (entity.id) {
      this.mypbAdminBlocksService.blocksAdminControllerRemove({
        id: entity.id,
        technicalName: entity.technicalName,
      }).subscribe({
        next: () => {
          this.page?.blocks.splice(this.page?.blocks.findIndex(block => block.id === entity.id), 1);
        },
      });
    }
  }
}
