import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  MypbAdminProfilesService,
  MypbImage,
  MypbProfile,
  MypbVideo,
  MypbWpUser,
} from '../../../_generated/mypagebuilder-rest-api';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import swal from 'sweetalert2';

@Component({
  selector: 'app-profiles-details-page',
  templateUrl: './profiles-details-page.component.html',
  styleUrls: ['./profiles-details-page.component.scss'],
})
export class ProfilesDetailsPageComponent {
  @Output() onSuccess: EventEmitter<void> = new EventEmitter<void>();
  public formGroup: FormGroup;

  constructor(
    private readonly store: Store,
    private readonly mypbAdminProfilesService: MypbAdminProfilesService,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.formGroup = new FormGroup({
      id: new FormControl('', []),
      createdAt: new FormControl('', []),
      modifiedAt: new FormControl('', []),
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      contentTitle: new FormControl('', [Validators.required]),
      contentLeadtext: new FormControl('', [Validators.required]),
      wpUserId: new FormControl('', [Validators.required]),
      contentImageId: new FormControl(null, []),
      contentVideoId: new FormControl(null, []),
    });
  }

  ngAfterViewInit() {
    if (this.activatedRoute.snapshot.params['id']) {
      this.mypbAdminProfilesService
        .profilesAdminControllerFindOne({
          id: this.activatedRoute.snapshot.params['id'],
        })
        .subscribe(value => {
          const { contentImage, contentVideo, pages, ...entity } = value;
          this.formGroup.setValue(entity);
        });
    }
  }

  /**
   * Creating/updating entry when clicking the save button.
   */
  public save() {
    if (this.activatedRoute.snapshot.params['id']) {
      this.update().subscribe({
        next: this.next,
        error: this.error,
      });
    } else {
      this.create().subscribe({
        next: this.next,
        error: this.error,
      });
    }
  }

  public setWpUser(wpUser: MypbWpUser) {
    this.formGroup.patchValue({
      wpUserId: wpUser.id,
    });
  }

  public onImageUploaded(image: MypbImage) {
    swal.fire('Bild hochgeladen', 'Jetzt noch auf "Speichern" klicken.', 'success');
    this.formGroup.patchValue({
      contentImageId: image.id,
    });
  }

  public onVideoUploaded(video: MypbVideo) {
    swal.fire('Video hochgeladen', 'Jetzt noch auf "Speichern" klicken.', 'success');
    this.formGroup.patchValue({
      contentVideoId: video.id,
    });
  }

  /**
   * Just the API call to update an existing entry.
   *
   * @private
   */
  private update() {
    return this.mypbAdminProfilesService.profilesAdminControllerUpdate({
      id: this.formGroup.value.id as string,
      body: this.formGroup.value,
    });
  }

  /**
   * Just the API call to create a new entry.
   *
   * @private
   */
  private create() {
    return this.mypbAdminProfilesService.profilesAdminControllerCreate({
      body: this.formGroup.value,
    });
  }

  /**
   * Fired after successfully saving the entry (applies to both created or updated).
   *
   * @param response
   */
  private next = (response: MypbProfile) => {
    swal.fire('Gespeichert!', '', 'success');
    this.onSuccess.emit();
  };

  /**
   * Fired after error during saving the entry (applies to both created or updated).
   */
  private error = () => {
    swal.fire('Oops', 'Beim speichern ist ein Fehler aufgetreten.', 'error');
  };
}
